<template>
    <van-row>
        <van-col v-if="isPc" :span="8"></van-col>
        <van-col :span="isPc ? 8 : 24">
            <van-form @submit="onSubmit" class="form-box">
                <template>
                    <div class="van-cell van-field" style="font-weight:bold;padding-left:10px;font-size:18px">数据库连接设置</div>
                    <van-cell-group>
                        <van-field v-model="config.username" label="用户名称" input-align="right"></van-field>
                        <van-field v-model="config.password" label="密码" input-align="right"></van-field>
                        <van-field v-model="config.host" label="服务器" input-align="right" ></van-field>
                        <van-field v-model="config.database" label="数据库名称" input-align="right"></van-field>
                        <van-field v-model="config.port" label="数据库端口" input-align="right"></van-field>
                    </van-cell-group>
                </template>
                <div class="form-button" :style="isPc ? `width:33.33%;` : 'width:100%;'">
                    <van-button round block type="info" native-type="submit" :disabled="isSave ? false : true">保存</van-button>
                </div>
            </van-form>
        </van-col>
        <van-col v-if="isPc" :span="8"></van-col>

        
        <!-- <div style="margin: 16px;" class="form-button">
            <van-button round block type="info" native-type="submit">提交</van-button>
        </div> -->
    </van-row>
</template>
<style>
.van-field--disabled > .van-field__label > span{
    color: #323232 !important;
}
.form-box {
    /* width: 100%; */
    min-height: 100vh;
    padding-bottom: 60px;
}
.form-button {
    margin-top:16px;
    background: white;
    position: fixed; /* 将按钮设置为固定定位 */  
    bottom: 0; /* 设置按钮距离底部的距离 */
    /* position: absolute;
    bottom: 0; */
}
.form-title {
    padding-left: 10px;
    font-size:16px;
    font-weight:bold;
}
.van-notify {  
    position: fixed !important;  
    top: 0 !important;  
    left: 0 !important;  
    right: 0 !important;  
    z-index: 9999 !important;  
}
</style>
<script>
    export default {
        name: 'center',
        components: {
        },
        props: {},
        data() {
            return {
                isSave: false, // 是否可以保存
                isPc: false, // 是否pc端
                config: {
                    username: '', // 用户名称
                    password: '', // 密码
                    host: '', // 服务器
                    database: '', // 数据库名称
                    port: '', // 数据库端口
                },
            }
        },
        computed: {
        },
        watch: {
        },
        methods: {

            async onSubmit() {
                var info = {}
                info = {...info, ...this.config};
                var headerInfo = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                var url = '/api/zhanjiang_fw/UpdateConfig';

                await this.$axios.post(url, info, headerInfo).then(async res => {
                    if(res.data.code == 1) {
                        this.$notify({
                            type: 'success',
                            message: '保存成功！'
                        });
                        this.getInfo();
                    } else {
                        this.$notify({
                            type: 'danger',
                            message: '保存失败！'
                        });
                    }
                })
            },
            async getInfo() {
                var info = {
                    a:1
                }
                var url = '/api/zhanjiang_fw/GetConfig';

                await this.$axios.post(url, this.$qs.stringify(info)).then(async res => {
                    if(res.data.code == 1) {
                        console.log(res);
                        this.isSave = true;
                        var list = res.data.config
                        this.config = {...this.config, ...list}
                    } else {
                        this.isSave = false;
                        this.$notify({
                            type: 'danger',
                            message: res.data.msg
                        });
                    }
                })
            }
        },
        created() {
            const userAgent = navigator.userAgent;  
            const isMobile = /Mobile|Android|iOS/.test(userAgent);  
            if (isMobile) {  
                console.log('是移动设备')
                this.isPc = false;
                // 是移动设备  
                // 执行相应的逻辑  
            } else {
                console.log('是PC设备  ')
                this.isPc = true;
                // 是PC设备  
                // 执行相应的逻辑  
            }
            this.getInfo();
        },
        destroyed() {
        },
        mounted() {
        }
    }
</script>